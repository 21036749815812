.discount_types_screen {
  @include media-max(720px) {
    .align_row {
      flex-direction: column;
      align-items: flex-start;
      .table_inline_form {
        grid-template-columns: 100%;
        margin-top: 20px;
        .input_holder {
          width: 100% !important;
        }
      }
    }
  }
  .discounts_type_form {
    width: auto;
    display: grid;
    grid-template-columns: 250px 300px auto auto auto auto;
    grid-gap: 20px;
    @include media-max(1000px) {
      grid-template-columns: 200px 250px auto auto auto auto;
    }
    @include media-max(1000px) {
      grid-template-columns: 150px 250px auto auto auto auto;
    }
    button {
      margin: 0;
      @include media-max(720px) {
        width: 150px;
      }
    }
  }
  .table-discountTypes {
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 400px 1fr 200px 120px;
      @include media-max(600px) {
        grid-template-columns: 1fr 70px 100px 120px;
      }
      & > .table_cell:last-child {
        @include flex-align-center;
        justify-content: flex-end;
      }
    }
  }
  @include media-max(500px) {
    .table_helper {
      .align_row {
        .table_inline_form {
          width: calc(100% - 100px);
          .input_holder {
            width: calc(100% - 65px);
          }
          button {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .inline_update_form {
    display:flex;
    .input_holder{
      width: 200px;
    }
    // grid-template-columns: 200px 1fr 60px;
    @include media-max(850px) {
      // grid-template-columns: 120px 1fr 60px;
    }
    @include media-max(650px) {
      // grid-template-columns: 80px 1fr 60px;
      // grid-gap: 5px;
      // padding: 0;
    }
    // .input_holder {
    //   width: 100% !important;
    // }
    label {
      display: none;
    }
    .product_brand_field {
      width: 300px;
      @include media-max(850px) {
        width: 250px;
      }
      @include media-max(650px) {
        width: 100%;
      }
    }
  }
}
