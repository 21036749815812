.product_page_wrap {
  @include media-max(750px) {
    .table_helper {
      .align_row {
        &:last-child {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
        .table_helper_icons {
          margin-top: 20px;
        }
      }
    }
  }
}

.table-products {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 60px 170px 140px 250px 170px 90px 90px 90px 90px 90px 100px 80px 80px;
    @include media-max(1800px) {
      grid-template-columns: 50px 170px 170px 1fr 250px 100px 100px 100px;
    }
    @include media-max(1400px) {
      grid-template-columns: 50px 150px 1fr 200px 100px 100px;
    }
    @include media-max(1000px) {
      grid-template-columns: 50px 150px 1fr 250px 100px;
    }
    @include media-max(750px) {
      grid-template-columns: 50px 1fr 100px;
    }
    @include media-max(500px) {
      grid-template-columns: 50px 1fr;
    }
  }
  .header {
    @include media-max(1800px) {
      padding-left: 45px !important;
      .table_cell {
        &:nth-child(1) {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
        }
        &:nth-child(3) {
          display: flex !important;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: flex !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          display: flex !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(6) {
          display: flex !important;
          @include media-max(1400px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .table_row {
    cursor: pointer;
    .table_cell {
      &.p15-0 {
        height: 45px;
        a {
          height: 100%;
          @include flex-align-center;
        }
      }
    }
    @include media-max(1800px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 8px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(2) {
          display: flex !important;
          width: 40px !important;
          margin: 0 10px !important;
        }
        &:nth-child(3) {
          display: flex !important;
          width: 200px !important;
          @include media-max(1400px) {
            width: 150px !important;
          }
          @include media-max(750px) {
            width: unset !important;
            flex: 1;
          }
        }
        &:nth-child(4) {
          display: flex !important;
           flex: 1;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          display: flex !important;
          width: 250px !important;
          p {
            padding-right: 10px;
            overflow: hidden;
            width: 90%;
          }
          @include media-max(1400px) {
            width: 200px !important;
          }
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(6) {
          display: flex !important;
          width: 100px !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(7) {
          display: flex !important;
          width: 100px !important;
          @include media-max(1400px) {
            display: none !important;
          }
        }
        display: none;
      }
      .product_table_icons {
        width: 100px;
        @include media-max(500px) {
          display: none !important;
        }
      }
    }
    .mobile_table_wrapper {
      @include media-max(1800px) {
        display: block;
        .mobile_table_holder .mobile_table_row .row_label {
          min-width: 120px;
          @include media-max(500px) {
            min-width: 105px;
          }
        }
        .linking_products_mobile {
          .row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            &.top {
              font-weight: bold;
              background: rgba($color: $color-black, $alpha: 0.05);
            }
            & > div {
              padding: 5px;
              @include media-max(950px) {
                font-size: 12px;
              }
              @include media-max(600px) {
                font-size: 10px;
              }
            }
          }
        }
        .mobile_table_row:nth-child(1) {
          display: none;
          @include media-max(750px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(2) {
          display: none;
          @include media-max(1800px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(3) {
          display: none;
          @include media-max(1800px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(4) {
          display: none;
          @include media-max(1800px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(5) {
          display: none;
          @include media-max(1400px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(9) {
          display: none;
          @include media-max(1800px) {
            display: grid;
            .row_content {
              .table_cell {
                display: block !important;
                font-size: 12px;
              }
            }
          }
        }
        .mobile_table_row:nth-child(10) {
          display: none;
          @include media-max(500px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(11) {
          display: none;
          @include media-max(500px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(12) {
          display: none;
          @include media-max(500px) {
            display: grid;
          }
        }
      }
    }
  }
  .table_row {
    .table_cell {
      &:last-child {
        overflow: unset !important;
      }
    }
  }
}

.table-productAttributes {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 30px 30px 1fr 80px 50px 50px;
    @include media-max(500px) {
      grid-template-columns: 30px 30px 1fr 50px 40px 40px;
    }
  }
  .header {
    .table_cell:nth-child(1) {
      padding: 3px 0 0 8px;
    }
  }
  .row_wrap {
    &:nth-child(even) {
      background: #f8f8f8;
    }
  }
  .table_row {
    cursor: pointer;
  }
}

.mass_update_desc_modal {
  .MuiPaper-root {
    min-width: 600px;
    padding: 20px;
    @include media-max(1000px) {
      min-width: unset;
    }
    .MuiDialogTitle-root {
      padding: 0;
    }
    .MuiDialogContent-root {
      padding: 20px 0;
    }
    .MuiDialogActions-root {
      padding: 0;
    }
  }
}

.mass_update_description {
  position: relative;
  .dropdown_wrap {
    position: absolute;
    top: calc(100% + 5px);
    width: 200px;
    right: 0;
    background: $color-white;
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    @include media-max(750px) {
      right: unset;
      left: 0;
    }
    ul {
      li {
        width: 100%;
        padding: 10px 15px;
        color: rgba($color: $color-black, $alpha: 0.4);
        font-size: 13px;
        cursor: pointer;
        @include hover {
          background: $color-grey-10;
        }
      }
    }
  }
}

.discounts_fields {
  margin: 60px 0 !important;
  @include media-min(1000px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

.product_table_icons {
  @include flex-align-center;
  & > .tooltip_wrap:nth-child(2) {
    margin: 0 5px;
  }
  .linking_modal_wrap_button {
    position: relative;
    .linking_product_modal_wrapper {
      position: absolute;
      right: 0;
      top: 100%;
      background: $color-white;
      z-index: 2;
      border-radius: 5px;
      width: 800px;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      @include media-max(1000px) {
        width: 600px;
      }
      @include media-max(750px) {
        width: 400px;
      }
      .linking_product_modal_content {
        @include full-width;
        .row {
          display: grid;
          grid-template-columns: 1fr 1fr 150px 40px;
          align-items: center;
          & > div {
            @include table-text;
            width: 100%;
            padding: 10px 15px;
            @include media-max(750px) {
              font-size: 12px;
            }
          }
          &.top {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            & > div {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.table-product_create_category_attribute {
  .header,
  .table_row {
    @include flex-align-center;
  }
  .holder {
    &:nth-child(even) {
      background: #f8f8f8;
    }
  }
  .table_row {
    cursor: pointer;
  }
  .values_table {
    background: #f1f1f1;
    padding-left: 50px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 24px;
      left: 24px;
      width: 2px;
      background: #e7e3e3;
    }
    .table_row {
      background: #f1f1f1 !important;
      cursor: auto;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        left: -24px;
        height: 2px;
        background: #e7e3e3;
      }
      .table_cell:last-child {
        @include flex-align-center;
        justify-content: flex-end;
      }
    }
  }
}

.product_attribute_table_values {
  background: #f1f1f1;
  padding: 0 14px 0 50px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 24px;
    left: 24px;
    width: 2px;
    background: #e7e3e3;
  }
  .table_row {
    @include flex-between;
    background: #f1f1f1;
    position: relative;
    .product_attribute_table_values {
      .table_row {
        :hover {
          background: #7367f0 !important;
          .table_cell {
            color: #fff;
          }
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      left: -24px;
      height: 2px;
      background: #e7e3e3;
    }
    .table_cell:last-child {
      @include flex-align-center;
      justify-content: flex-end;
    }
  }
}

.products_create_attribute_form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 0 20px;
}

.product_brand_field {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0 5px;
  .row {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0 10px;
    .input_holder {
      position: relative;
      .tooltip_wrap {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        color: $color-danger;
        z-index: 4;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.products_list_of_images {
  margin-top: -50px;
  .dropzone_wrapper {
    width: 100%;
    &.main_photo {
      .photo {
        border-radius: 4px;
        @include inline-bg;
        position: relative;
        height: 250px;
        @include media-max(1600px) {
          height: 200px;
        }
        svg {
          position: absolute;
          top: 5px;
          right: 5px;
          color: $color-danger;
          font-size: $size-icon-sm;
          cursor: pointer;
          font-size: 30px;
          @include media-max(1600px) {
            font-size: 24px;
          }
        }
      }
    }
    .input_file_holder {
      height: 100px;
      border: 1px dashed rgba($color: $color-black, $alpha: 0.4);
      position: relative;
      cursor: pointer;
      margin: 20px 0;
      input {
        @include absolute-full;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
      }
      span {
        @include absolute-full;
        @include flex-center;
        font-size: 18px;
        cursor: pointer;
        color: rgba($color: $color-black, $alpha: 0.4);
      }
    }
    .photos_grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      @include media-max(500px) {
        grid-template-columns: 100%;
      }
      .photo {
        border-radius: 4px;
        @include inline-bg;
        height: 250px;
        position: relative;
        @include media-max(1600px) {
          height: 200px;
        }
        @include media-max(500px) {
          height: 170px;
        }
        svg {
          position: absolute;
          top: 5px;
          right: 5px;
          color: $color-danger;
          font-size: $size-icon-sm;
          cursor: pointer;
          font-size: 30px;
          @include media-max(1600px) {
            font-size: 24px;
          }
        }
      }
    }
  }
  .upload_photos_row {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 25% calc(75% - 20px);
    grid-gap: 0 20px;
    @include media-max(1000px) {
      grid-template-columns: 100%;
      grid-gap: 20px 0;
    }
  }
}

.update_product_page_index {
  .product_name {
    font-weight: 500;
    font-size: 18px;
    border-radius: 10px;
    background: $color-white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 20px;
    margin-bottom: 20px;
  }
  .box_holder {
    position: relative;
    padding-top: 60px;
    .main_nav {
      border-bottom: 1px solid #ddd;
      height: 50px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      @include flex-align-center;
      padding: 0 20px;
      a {
        @include flex-align-center;
        color: $color-text;
        margin-right: 20px;
        height: 100%;
        &.active {
          color: $color-purple;
          border-bottom: 1px solid $color-purple;
        }
      }
    }
    .form_holder {
      padding-top: 20px;
      .form_group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        @include media-max(1000px) {
          grid-template-columns: 100%;
        }
        &.discounts_fields {
          margin: 0 0 20px 0 !important;
        }
      }
      .form_buttons_group {
        &.three_buttons {
          display: grid;
          grid-template-columns: repeat(3, auto);
          grid-gap: 20px;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
    .products_list_of_images {
      margin: 0px;
      .upload_photos_row {
        margin-top: 0;
      }
    }
    .products_create_attribute_form {
      margin-top: 20px;
    }
  }
  .create_value_modal_inner {
    @include fixed-full;
    @include flex-center;
    background: rgba($color: $color-black, $alpha: 0.5);
    z-index: 9999;
    .create_value_modal_outer {
      padding: 50px 20px;
      width: 600px;
      border-radius: 10px;
      background: $color-white;
      display: flex;
      justify-content: center;
      .inline_update_form {
        position: static;
        top: unset;
        left: unset;
        height: unset;
        grid-template-columns: 300px auto;
        padding: 0;
        border: 0;
        .input_holder {
          width: 100% !important;
        }
      }
      .add_value_dropdown {
        display: grid;
        grid-template-columns: 300px auto;
        align-items: center;
        grid-gap: 20px;
      }
    }
  }
  .skeleton_inner {
    .discounts_row {
      display: grid;
      grid-template-columns: repeat(3, 1fr) !important;
      margin-bottom: 20px;
      @include media-max(1000px) {
        grid-template-columns: 100% !important;
      }
    }
    .dropzones {
      display: grid;
      grid-template-columns: 25% calc(75% - 20px);
      grid-gap: 20px;
      margin-bottom: 20px;
      @include media-max(1000px) {
        grid-template-columns: 100% !important;
      }
      .dropzone {
        border-radius: 10px;
        width: 100%;
        height: 100px;
      }
    }
    .attributes_rows {
      .input {
        margin-bottom: 20px;
      }
    }
  }
}

.cms_product_attributes_table {
  .header {
    background: #e7e3e3;
  }
  .table_row {
    position: relative;
    cursor: pointer;
  }
  .content {
    &:nth-child(even) {
      background: #f8f8f8;
    }
    :hover:not(.product_attribute_table_values) {
      background: #7367f0;
      .table_cell {
        color: #fff;
      }
    }
  }

  .header,
  .table_row {
    height: 50px;
    padding: 0 10px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 50px;
  }
  .table_cell {
    @include table-text;
    @include media-max(1600px) {
      font-size: 13px;
    }
    @include media-max(1400px) {
      font-size: 11px;
    }
  }
  .product_attribute_table_values {
    .table_row {
      @include flex-between;
    }
  }
}

.pass_products_dialog {
  .MuiDialog-paperScrollPaper {
    padding: 50px 20px 10px 20px;
    overflow: unset;
    .search_select_wrapper {
      width: 400px;
      margin-bottom: 20px;
      @include media-max(700px) {
        width: 300px;
      }
      @include media-max(400px) {
        width: 260px;
      }
    }
    button {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  .list_of_attributes {
    h3 {
      font-weight: 500;
      text-align: center;
      font-size: 18px;
      margin-bottom: 20px;
    }
    ul {
      li {
        background: $color-grey-10;
        border-bottom: 1px solid $color-white;
        padding: 7px 10px;
        font-size: 14px;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

.table-ok-nok {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    // grid-template-columns: 2% 8% 5% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 8%;
    grid-template-columns: 3% 27% 10% 8% 8% 8% 10% 6% 10% 10%; 
    @include media-max(1800px) {
      // grid-template-columns: 2% 8% 5% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 8%;
      grid-template-columns: 3% 27% 10% 8% 8% 8% 10% 6% 10% 10%;  
    }
    @include media-max(1400px) {
      // grid-template-columns: 2% 8% 5% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 8%;
      grid-template-columns: 3% 27% 10% 8% 8% 8% 10% 6% 10% 10%; 
    }
    @include media-max(1000px) {
      grid-template-columns: 3% 27% 10% 8% 8% 8% 10% 6% 10% 10%; 
      // grid-template-columns: 50px 150px 1fr 250px 100px;
    }
    @include media-max(750px) {
      grid-template-columns: 50px 1fr 100px;
    }
    @include media-max(500px) {
      grid-template-columns: 50px 1fr;
    }
  }
  .header {
    @include media-max(1800px) {
      .table_cell {
        &:nth-child(1) {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
        }
        &:nth-child(3) {
          display: flex !important;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: flex !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          display: flex !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(6) {
          display: flex !important;
          @include media-max(1400px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .table_row {
    cursor: pointer;
    .table_cell {
      &.p15-0 {
        a {
          height: 100%;
          @include flex-align-center;
        }
      }
    }
    @include media-max(1800px) {
      height: unset !important;
      padding: 8px 0px !important;
      .expand_button {
        display: block;
      }
    }
  }
  .table_row {
    .table_cell {
      &:last-child {
        overflow: unset !important;
      }
    }
  }
  .content {
    max-height: 350px;
    overflow: auto;
  }
}

.table-ok-nokAttributes {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 10% 30% 20% 20% 20%;
    @include media-max(500px) {
      grid-template-columns: 10% 30% 20% 20% 20%;
    }
  }
  .header {
    .table_cell:nth-child(1) {
      padding: 3px 0 0 8px;
    }
  }
  .row_wrap {
    &:nth-child(even) {
      background: #f8f8f8;
    }
  }
  .table_row {
    cursor: pointer;
  }
}

.ok-nok_attributes_table {
  .header {
    background: #e7e3e3;
  }
  .table_row {
    position: relative;
    cursor: pointer;
  }
  .content {
    &:nth-child(even) {
      background: #f8f8f8;
    }
    :hover:not(.product_attribute_table_values) {
      background: #7367f0;
      .table_cell {
        color: #fff;
      }
    }
  }

  .header,
  .table_row {
    height: 50px;
    padding: 0 10px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 50px;
  }
  .table_cell {
    @include table-text;
    @include media-max(1600px) {
      font-size: 13px;
    }
    @include media-max(1400px) {
      font-size: 11px;
    }
  }
  .product_attribute_table_values {
    .table_row {
      @include flex-between;
    }
  }
}

.table-ok-nok2 {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    // grid-template-columns: 2% 8% 5% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 8%;
    grid-template-columns: 40% 20% 20% 20%;
    @include media-max(1800px) {
      // grid-template-columns: 2% 8% 5% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 8%;
      grid-template-columns: 40% 20% 20% 20%;
    }
    @include media-max(1400px) {
      // grid-template-columns: 2% 8% 5% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 5% 8% 8%;
      grid-template-columns: 40% 20% 20% 20%;
    }
    @include media-max(1000px) {
      grid-template-columns: 40% 20% 20% 20%;
    }
    @include media-max(750px) {
      grid-template-columns: 40% 20% 20% 20%;
    }
    @include media-max(500px) {
      grid-template-columns: 40% 20% 20% 20%;
    }
  }
  .header {
    @include media-max(1800px) {
      .table_cell {
        &:nth-child(1) {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
        }
        &:nth-child(3) {
          display: flex !important;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: flex !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          display: flex !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(6) {
          display: flex !important;
          @include media-max(1400px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .table_row {
    cursor: pointer;
    .table_cell {
      &.p15-0 {
        a {
          height: 100%;
          @include flex-align-center;
        }
      }
    }
    @include media-max(1800px) {
      height: unset !important;
      padding: 8px !important;
      .expand_button {
        display: block;
      }
    }
  }
  .table_row {
    .table_cell {
      &:last-child {
        overflow: unset !important;
      }
    }
  }
  .content {
    max-height: 350px;
    overflow: auto;
  }
}

.table-ok-nok2Attributes {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 40% 20% 20% 20%;
    @include media-max(500px) {
      grid-template-columns: 40% 20% 20% 20%;
    }
  }
  .header {
    .table_cell:nth-child(1) {
      padding: 3px 0 0 8px;
    }
  }
  .row_wrap {
    &:nth-child(even) {
      background: #f8f8f8;
    }
  }
  .table_row {
    cursor: pointer;
  }
}

.ok-nok2_attributes_table {
  .header {
    background: #e7e3e3;
  }
  .table_row {
    position: relative;
    cursor: pointer;
  }
  .content {
    &:nth-child(even) {
      background: #f8f8f8;
    }
    :hover:not(.product_attribute_table_values) {
      background: #7367f0;
      .table_cell {
        color: #fff;
      }
    }
  }

  .header,
  .table_row {
    height: 50px;
    padding: 0 10px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 50px;
  }
  .table_cell {
    @include table-text;
    @include media-max(1600px) {
      font-size: 13px;
    }
    @include media-max(1400px) {
      font-size: 11px;
    }
  }
  .product_attribute_table_values {
    .table_row {
      @include flex-between;
    }
  }
}

.product_page_wrap_eoffer {
  @include media-max(750px) {
    .table_helper {
      .align_row {
        &:last-child {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
        .table_helper_icons {
          margin-top: 20px;
        }
      }
    }
  }
}

.table-eoffer {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 200px 1fr 250px 100px 100px;
    @include media-max(1800px) {
      grid-template-columns: 50px 200px 1fr 250px 100px 100px;
    }
    @include media-max(1400px) {
      grid-template-columns: 50px 150px 1fr 200px 100px 100px;
    }
    @include media-max(1000px) {
      grid-template-columns: 50px 150px 1fr 250px 100px;
    }
    @include media-max(750px) {
      grid-template-columns: 50px 1fr 100px;
    }
    @include media-max(500px) {
      grid-template-columns: 50px 1fr;
    }
  }
  .header {
    @include media-max(1800px) {
      padding-left: 45px !important;
      .table_cell {
        &:nth-child(1) {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
        }
        &:nth-child(3) {
          display: flex !important;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: flex !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          display: flex !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(6) {
          display: flex !important;
          @include media-max(1400px) {
            display: none !important;
          }
        }
        display: none;
      }
    }
  }
  .table_row {
    cursor: pointer;
    .table_cell {
      &.p15-0 {
        height: 45px;
        a {
          height: 100%;
          @include flex-align-center;
        }
      }
    }
    @include media-max(1800px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 8px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(2) {
          display: flex !important;
          width: 40px !important;
          margin: 0 10px !important;
        }
        &:nth-child(3) {
          display: flex !important;
          width: 200px !important;
          @include media-max(1400px) {
            width: 150px !important;
          }
          @include media-max(750px) {
            width: unset !important;
            flex: 1;
          }
        }
        &:nth-child(4) {
          display: flex !important;
          flex: 1;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          display: flex !important;
          width: 250px !important;
          p {
            padding-right: 10px;
            overflow: hidden;
            width: 90%;
          }
          @include media-max(1400px) {
            width: 200px !important;
          }
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(6) {
          display: flex !important;
          width: 100px !important;
          @include media-max(1000px) {
            display: none !important;
          }
        }
        &:nth-child(7) {
          display: flex !important;
          width: 100px !important;
          @include media-max(1400px) {
            display: none !important;
          }
        }
        display: none;
      }
      .product_table_icons {
        width: 100px;
        @include media-max(500px) {
          display: none !important;
        }
      }
    }
    .mobile_table_wrapper {
      @include media-max(1800px) {
        display: block;
        .mobile_table_holder .mobile_table_row .row_label {
          min-width: 120px;
          @include media-max(500px) {
            min-width: 105px;
          }
        }
        .linking_products_mobile {
          .row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            &.top {
              font-weight: bold;
              background: rgba($color: $color-black, $alpha: 0.05);
            }
            & > div {
              padding: 5px;
              @include media-max(950px) {
                font-size: 12px;
              }
              @include media-max(600px) {
                font-size: 10px;
              }
            }
          }
        }
        .mobile_table_row:nth-child(1) {
          display: none;
          @include media-max(750px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(2) {
          display: none;
          @include media-max(1800px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(3) {
          display: none;
          @include media-max(1800px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(4) {
          display: none;
          @include media-max(1800px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(5) {
          display: none;
          @include media-max(1400px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(9) {
          display: none;
          @include media-max(1800px) {
            display: grid;
            .row_content {
              .table_cell {
                display: block !important;
                font-size: 12px;
              }
            }
          }
        }
        .mobile_table_row:nth-child(10) {
          display: none;
          @include media-max(500px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(11) {
          display: none;
          @include media-max(500px) {
            display: grid;
          }
        }
        .mobile_table_row:nth-child(12) {
          display: none;
          @include media-max(500px) {
            display: grid;
          }
        }
      }
    }
  }
  .table_row {
    .table_cell {
      &:last-child {
        overflow: unset !important;
      }
    }
  }
}
