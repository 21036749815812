.api_client_modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #0000003f;
  display: flex;
  justify-content: center;
  align-content: space-around;

  div {
    margin: auto;
    position: relative;
    width: 20%;
    background-color: white;
    border-radius: 0.5vw;

    div {
      display: flex;
      margin-top: 10%;
      margin-bottom: 10%;
      width: 70%;
      justify-content: space-between;
      flex-direction: column;
      p {
        display: flex;
        justify-content: space-between;

        .percentage {
          margin-left: 10%;
        }
      }
    }
    @include media-max(850px) {
      width: 90%;
    }

    .close_button {
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgb(240, 240, 240);
      margin: 2%;
    }
    p {
      margin: 5%;
      snap {
        color: lightgray;
        margin-right: 5%;
        margin-left: 5%;
      }
    }
  }
}

.table-api_client {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 200px 600px 100px 100px 100px 50px;
    @include media-max(1300px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    & > .table_cell:last-child {
      @include flex-align-center;
      justify-content: flex-end;
    }
  }
}

.api_cell {
  cursor: pointer;
}

.api_client_edit {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #0000003f;
  display: flex;
  justify-content: center;
  align-content: space-around;

  div {
    margin: auto;

    border-radius: 0.5vw;
    width: 40%;
    background-color: white;
    height: min-content;
    position: relative;

    @include media-max(600px) {
      width: 90%;
    }

    .close_button {
      position: absolute;
      margin: 2%;
      top: 0;
      right: 0;
      z-index: 99;

      background: #e7e7e788;
    }

    .title {
      margin: 5%;
      snap {
        color: lightgray;
        margin-right: 5%;
        margin-left: 5%;
      }
    }
    .field_title {
      margin: 5%;
    }
    .api_field {
      display: flex;
      width: 70%;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-top: 2%;
      p {
        width: 100%;
        text-align: right;
      }
      .api_percentage {
        height: 30px;
        width: 70px;
      }
    }

    .api_form {
      padding-bottom: 5%;
      overflow-y: scroll;
      .button {
        width: 60%;
        margin: auto;

        margin-top: 5%;
        margin-bottom: 5%;
      }
      .name {
        width: 200px;
        transform: translateX(-25%);
        height: 40px;
      }
    }
  }
}
