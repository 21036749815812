/* Fonts */

$font-roboto: Montserrat, Helvetica, Arial, sans-serif;

$font-size-body: 16px;
$font-size-stack: 1rem;
$font-size-xxxl: 1rem * 2.75;
$font-size-xxl: 1rem * 2.5;
$font-size-xl: 1rem * 2;
$font-size-lg: 1rem * 1.5;
$font-size-md: 1rem * 1.25;
$font-size-sm: 1rem * 0.75;
$font-size-xs: 1rem * 0.625;
$font-size-regular: 0.875em;

$font-line-height-stack: 1.5;
$font-line-height-sm: 1.3;
$font-line-height-xs: 1.2;

/* Colors */

$color-white: #fff;
$color-white-02: #f5f5f5;
$color-white-03: #e2e2e2;

$color-black: #000;
$color-black-02: #333;
$color-black-03: #555;
$color-text: #333e48;
$color-root: #626262;

$color-grey-border: #e0e0e0;
$color-grey: #cad5d5;
$color-grey-01: #ddd;
$color-grey-02: #a4a4a4;
$color-grey-03: #334141;
$color-grey-04: #a7a7a7;
$color-grey-05: #6b6b6b;
$color-grey-06: #aeaeae;
$color-grey-07: #dadada;
$color-grey-08: #818181;
$color-grey-09: #f8f9fa;
$color-grey-10: #f8f8f8;
$color-grey-11: #f0f0f0;

$color-blue-01: #215896;
$color-blue-02: #203f63;
$color-blue-03: #0275d8;
$color-blue-04: #10163a;

$color-primary: #0787ea;
$color-success: rgb(86, 201, 58);
$color-danger: rgb(234, 84, 85);
$color-warning: #ffc107;
$color-secondary: #b9b9b9;
$color-purple: #89BD2D;
$color-purple-02: rgb(22, 163, 3);

$color-primary-hover: rgb(14, 123, 206);
$color-success-hover: rgb(124, 187, 52);
$color-danger-hover: rgb(219, 11, 11);

$color-table-header: #f1f1f1;
$color-table-hover: rgb(248, 248, 248);

/* Radius */

$radius-round: 50%;
$radius-pill: 100px;
$radius: 4px;
$radius-sm: 2px;
$radius-lg: 0.5rem;
$radius-xl: 0.75rem;

/* Spacings */

$gutter: 1rem;
$gutter-xs: $gutter * 0.25;
$gutter-sm: $gutter * 0.5;
$gutter-075: $gutter * 0.75;
$gutter-xl: $gutter * 1.5;
$gutter-2g: $gutter * 2;
$gutter-3l: $gutter * 3;
$gutter-4xl: $gutter * 4;

/* Sizes */

$size-main-container: 84rem; // 1344px

/* Icons */

$size-icon-xs: 0.9rem;
$size-icon-sm: 1rem;
$size-icon: 1.2rem;
$size-icon-md: 1.75rem;
$size-icon-lg: 2rem;
$size-icon-xl: 2.75rem;

$size-header: 6rem;

/* Screens */

$screen-full-hd: 120rem; // 1920px
$screen-tablet-landscape: 64rem; // 1024px
$screen-tablet-portrait: 48rem; // 768px
$screen-mobile-landscape: 46.25rem; // 740px
$screen-mobile-portrait: 23.4375rem; // 375px
$screen-mobile-sm-portrait: 20rem; // 320px
